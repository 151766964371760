<template>
  <div class="background" :style="'background-image:url('+ Background +');'">
    <div class="logo">
      <a href="http://yideio.cn"> <img src="@/assets/ydcoin-logo.png" alt="YdCoin"></a>
    </div>
    <div class="register">
      <el-form ref="registerForm" :model="registerForm" :rules="registerRules" label-position="left" label-width="0px"
        class="register-form">
        <h3 class="title">
          注 册
        </h3>
        <el-form-item prop="username">
          <el-input v-model="registerForm.username" type="text" auto-complete="off" placeholder="用户名（字母加数字组合）">
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="registerForm.password" type="password" auto-complete="off" placeholder="密码"
            @keyup.enter.native="handleRegister">
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input v-model="registerForm.confirmPassword" type="password" auto-complete="off" placeholder="确认密码"
            @keyup.enter.native="handleRegister">
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="realname">
          <el-input v-model="registerForm.realname" type="text" auto-complete="off" placeholder="真实姓名"
            @keyup.enter.native="handleRegister">
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="registerForm.email" type="text" auto-complete="off" placeholder="邮箱"
            @keyup.enter.native="handleRegister">
            <svg-icon slot="prefix" icon-class="email" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="registerForm.phone" type="text" auto-complete="off" placeholder="手机号"
            @keyup.enter.native="handleRegister">
            <svg-icon slot="prefix" icon-class="phone" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="invaliatedCode">
          <el-input v-model="registerForm.invaliatedCode" type="text" auto-complete="off" placeholder="邀请码"
            @keyup.enter.native="handleRegister">
            <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="phoneCode">
          <el-input v-model="registerForm.phoneCode" type="text" auto-complete="off" placeholder="手机验证码"
            @keyup.enter.native="handleRegister">
            <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
            <el-button type="primary" round slot="suffix" size="small" @click="getCode" v-if="enabled==false">发送验证码
            </el-button>
            <el-button type="info" round slot="suffix" size="small" :disabled="enabled" v-if="enabled==true">{{btntxt}}
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button :loading="loading" size="medium" type="primary" style="width:100%;"
            @click.native.prevent="handleRegister">
            <span v-if="!loading">注 册</span>
            <span v-else>注 册 中...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Background from "@/assets/images/background.jpg";
import { sendPhoneCode, register } from "@/api/register";
//import {MessageBox} from "element-ui";
import {
  validatePhone,
  validateEmail,
  validateRealName,
  validateName,
  validatePassword,
} from "@/utils/validate";

export default {
  name: "register",
  data() {
    var validateConfirmPass = (rule, value, callback) => {
      if (value !== this.registerForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      Background: Background,
      registerForm: {
        username: "",
        password: "",
        confirmPassword: "",
        realname: "",
        email: "",
        phone: "",
        invaliatedCode: "",
        phoneCode: "",
      },
      registerRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
          { validator: validateName, trigger: "blur" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
          { validator: validatePassword, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, trigger: "blur", message: "确认密码不能为空" },
          { validator: validateConfirmPass, trigger: "blur" },
        ],
        realname: [
          { required: true, trigger: "blur", message: "真实姓名不能为空" },
          { validator: validateRealName, trigger: "blur" },
        ],
        email: [
          { required: true, trigger: "blur", message: "邮箱不能为空" },
          { validator: validateEmail, trigger: "blur" },
        ],
        phone: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
          { validator: validatePhone, trigger: "blur" },
        ],
        phoneCode: [
          { required: true, trigger: "blur", message: "验证码不能为空" },
          { min: 4, max: 6, message: "长度在 4 到 6 位数字", trigger: "blur" },
        ],
      },
      loading: false,
      redirect: undefined,
      timer: null,
      btntxt: "发送验证码",
      enabled: false,
      count: 120,
    };
  },
  created() {
    // 获取地址参数
    console.log(this.$route.query);
    this.registerForm.invaliatedCode = this.$route.query["code"];
    console.log(this.registerForm.invaliatedCode);
  },
  methods: {
    getCode() {
      const promise1 = new Promise((resolve) => {
        this.$refs["registerForm"].validateField("phone", (err) => {
          resolve(err);
        });
        resolve();
      });
      Promise.all([promise1]).then((result) => {
        if (!result.join("")) {
          const TIME_COUNT = 120; // 3.设置时间为120s
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.enabled = true; // 4.隐藏获取验证码按钮,展示倒计时模块
            // 发送验证码请求
            sendPhoneCode(this.registerForm.phone).then((res) => {
              console.log(res);
              if (res.status == 0) {
                this.enabled = false;
                this.$message.error(res.msg);
              }
              if (res.status == 1) {
                this.$message.success(res.msg);
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    // 6.设置每秒钟递减
                    this.btntxt = this.count + "s后重发";
                    this.count--;
                  } else {
                    // 7.递减至0时,显示获取验证码按钮,隐藏倒计时模块,清除定时器this.timer,并将其置为null
                    this.enabled = false;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            });
          }
        } else {
          //this.disableGetCode = false;
          console.log("error!!");
        }
      });
    },
    handleRegister() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          const user = {
            username: this.registerForm.username,
            password: this.registerForm.password,
            password2: this.registerForm.confirmPassword,
            realName: this.registerForm.realname,
            email: this.registerForm.email,
            phone: this.registerForm.phone,
            code: this.registerForm.phoneCode,
            invitedCode: this.registerForm.invaliatedCode,
          };
          var data = JSON.stringify(user);
          register(data)
            .then((res) => {
              if (res.status == 15) {
                // 跳转到首页
                //this.$store.commit(LOGIN, res.data);
                this.$alert("注册成功！", "", {
                  confirmButtonText: "确定",
                  callback: () => {
                    this.$router.push({ path: this.redirect || "/" });
                  },
                });
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((error) => {
              console.log(error);
              if (error.response.status == 400) {
                console.log(error.response);
                this.$message.error(error.response.data.message);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100% - 54px;
  background-size: cover;
}
.background {
  height: 100%;
  background-size: cover;
}
.logo {
  // margin-bottom: 100px;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-left: 100px;
  cursor: pointer;
}
.title {
  margin: 0 auto 20px auto;
  text-align: center;
  color: #707070;
}
.register-form {
  border-radius: 6px;
  background: #ffffff;
  width: 385px;
  padding: 20px 20px 5px 20px;
  .el-form-item {
    height: 35px;
  }
  .el-input {
    height: 35px;

    input {
      height: 35px;
    }
  }
  ::v-deep .el-form-item__content {
    height: 35px;
  }
  ::v-deep .el-input__inner {
    height: 35px;
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
  .input-button {
    height: 35px;
    width: 90px;
    text-align: center;
  }
}
</style>