import request from '@/api/request'

export function register(data) {
  return request({
    url: 'api/v2/register',
    method: 'post',
    data: data
  })
}

export function sendPhoneCode(phone) {
  return request({
    url: 'api/v2/ssmcode',
    method: 'post',
    data: {
      phone: phone
    }
  })
}