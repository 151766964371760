import axios from 'axios'
//import router from '@/router/router'
import { Notification } from 'element-ui'
import Config from '@/settings'

// 创建axios实例
const service = axios.create({
  //baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL: "https://yideio.cn/",
  timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    // if (getToken()) {
    //     config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    console.log('err' + error)// for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    //console.log('err1' + error)// for debug
    console.log('err2' + error.response)// for debug
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Notification.error({
        title: '网络请求超时',
        duration: 5000
      })
      return Promise.reject(error)
    }
    if (error.toString().indexOf('Network Error') !== -1) {
      Notification.error({
        title: '请检查您的网络情况',
        duration: 5000
      })
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)
export default service
